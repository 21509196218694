import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { useTranslation } from 'react-i18next';
import { AssetByIdGet, getFileSize } from '../../../../../hooks/api/asset';
import { humanFileSize } from '../../../../../utils';
import List from '../List';

import styles from '../List/index.module.scss';

const excludeList = new Set([
  'geberit:description',
  'geberit:dam.public-url',
  'censhare:asset.description',
  'censhare:asset.annotation',
  'geberit:asset.category',
  'geberit:tag.type',
  'geberit:step.product-ref',
  'geberit:step.product-item-ref',
  'geberit:with-person',
  'geberit:tag.campaign',
  '',
]);

export interface PropertiesProps {
  data?: AssetByIdGet;
  className?: string | ClassValue;
}

export default function Properties({
  data = undefined, className = '',
}: PropertiesProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={clsx(className)}>
      <div className={styles.row}>
        <div>{t('id')}</div>
        <div>{data?.assetId}</div>
      </div>

      <List excludeList={excludeList} data={data?.allData?.metaData?.feature} />

      <div className={styles.row}>
        <div>{t('owner')}</div>
        <div>
          {data?.allData?.contacts.owners.owner.firstname}
          {' '}
          {data?.allData?.contacts.owners.owner.name}
        </div>
      </div>

      <div className={styles.row}>
        <div>{t('upload.MIMEType')}</div>
        <div>{data?.format || '-'}</div>
      </div>

      <div className={styles.row}>
        <div>{t('upload.fileSize')}</div>
        <div>{humanFileSize(getFileSize(data))}</div>
      </div>
    </div>
  );
}
