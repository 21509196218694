import React, { useEffect } from 'react';

import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../Icon';
import Point from '../../../Point';
import { AssetByIdGet } from '../../../../../hooks/api/asset';
import { ButtonAccept } from '../../Collections/Download';
import { ListSize } from '../../../../Pages/Share/SelectedList';

import styles from './index.module.scss';

interface DownloadProps {
  autoStart?: boolean;
  urlExport?: string;
  asset?: AssetByIdGet;
  isModalOpen: boolean | [ListSize];
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Download({
  autoStart = false, urlExport, asset, isModalOpen, handleOk, handleCancel,
}: DownloadProps): React.ReactNode {
  const { t } = useTranslation();

  useEffect(() => {
    if (autoStart && isModalOpen) {
      setTimeout(handleOk, 50);
    }
  }, [autoStart]);

  if (urlExport && asset && autoStart && isModalOpen) {
    return (
      <ButtonAccept
        autoStart={!!(autoStart && isModalOpen)}
        name={asset.name}
        assets={[asset]}
        handleCancel={handleCancel}
        text={t('preparationOfFiles')}
        url={`${process.env.REACT_APP_API}file/${asset?.id}/${
          Array.isArray(isModalOpen) ? isModalOpen[0] : 'export'}?t=${Date.now()}`}
      />
    );
  }

  return (
    <Modal
      width={420}
      title={t('usageRestrictionsInformation')}
      open={!!isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalDownload}
    >
      <div className={styles.content}>
        <div className={styles.name}>
          {asset?.allData?.name}
        </div>

        {asset?.restriction ? (
          <div className={styles.list}>
            <Point
              status={asset?.restriction}
              text={t(`restrictionsStatus.${asset?.restriction}`)}
            />
          </div>
        ) : null}
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          {t('cancel')}
        </Button>
        {urlExport && asset ? (
          <ButtonAccept
            autoStart={!!(autoStart && isModalOpen)}
            name={asset.name}
            assets={[asset]}
            handleCancel={handleCancel}
            text={t('preparationOfFiles')}
            url={`${process.env.REACT_APP_API}file/${asset?.id}/${
              Array.isArray(isModalOpen) ? isModalOpen[0] : 'export'}?t=${Date.now()}`}
          />
        ) : null}
      </div>
    </Modal>
  );
}
