import React, { useEffect } from 'react';

import { App, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../Icon';
import Point from '../../../Point';
import { AssetByIdGet } from '../../../../../hooks/api/asset';
import { ListSize, sizeAssetAlias } from '../../../../Pages/Share/SelectedList';
import { usePrepareFile, usePrepareFileId } from '../../../../../context/prepareFile';

import styles from './index.module.scss';

interface ButtonAcceptProps {
  type?: ListSize | boolean;
  autoStart?: boolean;
  name: string;
  assets: AssetByIdGet[];
  listChecked?: [string, ListSize][];
  handleCancel: () => void;
  text?: string;
  url?: string
}

export function ButtonAccept({
  type,
  autoStart = false,
  name,
  assets,
  listChecked,
  handleCancel,
  text,
  url,
}: ButtonAcceptProps) {
  const { t } = useTranslation();
  const { prepare } = usePrepareFile();
  const { setId, state, remove } = usePrepareFileId();
  const { message } = App.useApp();
  const listSize: Array<ListSize> = ['preview', 'export', 'master'];

  useEffect(() => {
    if (!state.loading && !state.error && state.success) {
      window.location.assign(url || `${process.env.REACT_APP_API}file?${state.queryParams}&filename=${name || ''}`);
      remove();
      handleCancel();
    }
  }, [state.success]);

  useEffect(() => {
    if (state.loading) {
      message.success(text || t('preparationOfFilesForArchiving'));
    }
  }, [state.loading]);

  useEffect(() => {
    if (autoStart) {
      if (assets.length === 1 && url) {
        window.location.assign(url);
      } else {
        setId(
          prepare(
            {
              ids: assets.map(({ id }) => id),
            },
            name,
          ),
        );
      }
    }
  }, [autoStart, url]);

  return (
    <Button
      style={autoStart ? { display: 'none' } : undefined}
      loading={state.loading}
      type="primary"
      size="large"
      onClick={(e) => {
        e.preventDefault();
        if (listChecked) {
          setId(
            prepare(
              {
                [sizeAssetAlias.preview]: listChecked
                  .filter(([_, filterType]) => filterType === listSize[0])
                  .map(([id]) => id),
                [sizeAssetAlias.export]: listChecked
                  .filter(([_, filterType]) => filterType === listSize[1])
                  .map(([id]) => id),
                [sizeAssetAlias.master]: listChecked
                  .filter(([_, filterType]) => filterType === listSize[2])
                  .map(([id]) => id),
              },
              name,
            ),
          );
        } else {
          setId(
            prepare(
              typeof type === 'string' ? ({
                [sizeAssetAlias[type]]: assets.map(({ id }) => id),
              }) : (
                {
                  ids: assets.map(({ id }) => id),
                }
              ),
              name,
            ),
          );
        }
      }}
    >
      {t('accept')}
    </Button>
  );
}

interface DownloadProps {
  type?: ListSize | boolean;
  name: string;
  assets: AssetByIdGet[];
  listChecked?: [string, ListSize][];
  isModalOpen: ListSize | boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Download({
  type, name, assets, listChecked, isModalOpen, handleOk, handleCancel,
}: DownloadProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal
      width={420}
      title={t('usageRestrictionsInformation')}
      open={!!isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalDownload}
    >
      <div className={styles.content}>
        <div className={styles.list}>
          {assets.map(({ id, name: assetName, restriction }) => (
            <div key={id} className={styles.item}>
              <div className={styles.name}>
                {assetName}
              </div>

              {restriction ? (
                <div className={styles.list}>
                  <Point
                    status={restriction}
                    text={t(`restrictionsStatus.${restriction}`)}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          {t('cancel')}
        </Button>
        <ButtonAccept
          type={type}
          name={name}
          assets={assets}
          listChecked={listChecked}
          handleCancel={handleCancel}
        />
      </div>
    </Modal>
  );
}
