import React, { useEffect, useState } from 'react';

import {
  Button, Form, Input, Modal,
} from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CloseIcon, DefaultImageIcon, PlusIcon } from '../../../Icon';
import {
  CollectionCreateForm,
  useCollectionCreate,
  useCollectionGet, useCollectionUpdate,
} from '../../../../../hooks/api/collection';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Loading from '../../../Loading';
import { AssetByIdGet } from '../../../../../hooks/api/asset';

import styles from './index.module.scss';
import ImgBackground from '../../../ImgBackground';

export const LIMIT_ASSETS_IN_COLLECTION = 50;

function addToAsset(assets: AssetByIdGet[], newId: string): string[] {
  let list = assets.map(({ id }) => id);

  if (!list.includes(newId)) {
    list = [newId, ...list];
  }

  return list;
}

interface AddCollectionProps {
  id: string; // asset UUID
  assetId?: string;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function AddCollection({
  id, isModalOpen, handleOk, handleCancel,
}: AddCollectionProps): React.ReactNode {
  const { t } = useTranslation();
  const [isAdd, setAdd] = useState(false);
  const [form] = Form.useForm();
  const collectionCreate = useCollectionCreate();
  const collectionUpdate = useCollectionUpdate();
  const collectionGet = useCollectionGet();
  const [triggerScroll, setTriggerScroll] = useState(false);

  useMessageError([collectionGet, collectionCreate, collectionUpdate]);
  useMessageSuccess([collectionCreate], t('create.collection.success'));
  useMessageSuccess([collectionUpdate], t('add.collection.success'));

  const getAllCollection = () => collectionGet.fetch({
    page: 1,
    pageSize: 240,
  });

  useEffect(() => {
    if (!collectionUpdate.error && !collectionUpdate.loading && collectionUpdate.response) {
      getAllCollection();
    }
  }, [collectionUpdate.response]);

  useEffect(() => {
    if (isModalOpen) {
      getAllCollection();
    }
  }, [isModalOpen, collectionCreate.data]);

  useEffect(() => {
    if (!collectionCreate.loading && !collectionCreate.error && collectionCreate.response) {
      form.resetFields();
      setAdd(false);
      getAllCollection();
      setTriggerScroll(true);
    }
  }, [collectionCreate.response]);

  useEffect(() => {
    if (!collectionGet.loading && triggerScroll && collectionGet.data?.data.length) {
      const scrollElement = document.querySelector('.add-collection-scroll-content');

      if (scrollElement) {
        scrollElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [triggerScroll, collectionGet.response]);

  let content: React.ReactNode | undefined;

  const handleSubmit = (formItems: CollectionCreateForm) => {
    collectionCreate.fetch(formItems);
  };

  if (collectionGet.data?.data.length) {
    content = (
      <div className={clsx(styles.content, 'add-collection-scroll-content')}>
        {collectionGet.data?.data.map((collection) => {
          const url = collection.assets.find(({ urlPreview }) => urlPreview)?.urlPreview;

          return (
            <div key={collection.id} className={styles.item}>
              <ImgBackground className={styles.img} url={url || '/resource/images/asset.png'} />

              <div className={styles.info}>
                <div className={styles.name}>
                  {collection.name}
                </div>
                <div className={styles.description}>
                  {collection.description}
                </div>
                <div className={styles.counter}>
                  {collection.assets.length}
                  {' '}
                  {t('items')}
                </div>
              </div>
              <div className={styles.btn}>
                <Button
                  disabled={
                  collection.assets.length >= LIMIT_ASSETS_IN_COLLECTION
                    || collection.assets.some(({ id: _id }) => _id === id)
                }
                  loading={collectionUpdate.loading}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    collectionUpdate.fetch({
                      assets: addToAsset(collection.assets, id),
                    }, collection.id);
                  }}
                >
                  {collection.assets.some(({ id: _id }) => _id === id) ? (
                    t('already')
                  ) : (
                    t('save')
                  )}
                </Button>
                {collection.assets.length >= LIMIT_ASSETS_IN_COLLECTION ? (
                  <span>
                    max
                    {' '}
                    {LIMIT_ASSETS_IN_COLLECTION}
                    {' '}
                    {t('assets').toLowerCase()}
                  </span>
                ) : null}
                <span />
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    content = (
      <div className={styles.noContent}>
        {t('noCollectionsAddedYet')}
        .
      </div>
    );
  }

  content = (
    <>
      {content}

      {isAdd ? (
        <div className={styles.content}>
          <div className={styles.new}>
            <DefaultImageIcon />
            <Form<CollectionCreateForm>
              requiredMark={false}
              name="trigger"
              layout="vertical"
              autoComplete="off"
              className={styles.info}
              form={form}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="name"
                validateDebounce={500}
                rules={[{
                  min: 2,
                  required: true,
                  message: t('required.name'),
                }, {
                  max: 255,
                  message: t('required.max255'),
                }]}
              >
                <Input placeholder={t('name')} />
              </Form.Item>

              <Form.Item
                name="description"
                validateDebounce={500}
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  placeholder={t('description')}
                />
              </Form.Item>
              <div style={{ marginTop: 4 }}>
                0
                {' '}
                {t('items')}
              </div>
            </Form>
          </div>

          <div className={styles.btnCenter}>
            <Button
              loading={collectionCreate.loading}
              type="primary"
              size="large"
              style={{ marginTop: 24, fontSize: 14 }}
              onClick={(e) => {
                e.preventDefault();
                form.submit();
              }}
            >
              {t('save')}
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.btnCenter}>
          <Button
            icon={<PlusIcon />}
            type="text"
            size="large"
            style={{ marginTop: 16 }}
            className={styles.addCollection}
            onClick={(e) => {
              e.preventDefault();
              setAdd(true);
            }}
          >
            {t('create.collection.title')}
          </Button>
        </div>
      )}
    </>
  );

  return (
    <Modal
      width={420}
      title={t('add.collection.title')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        setAdd(false);
        handleCancel();
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalCollection}
    >
      {content}
      {collectionGet.loading ? <Loading /> : null}
    </Modal>
  );
}
