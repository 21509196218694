import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.REACT_APP_ENV === 'local',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          owner: 'Owner',

          fileUploadQueue: 'File upload queue',
          yourDownloadItems: 'Your download items',
          preview: 'medium size',
          export: 'big size',
          master: 'original',

          file_one: 'file',
          file_other: 'files',
          selected: 'selected',

          required: {
            name: 'Please enter name',
            max255: 'Must be less than or equal to 255 characters',
          },

          id: 'ID',
          upload: {
            fileSize: 'Upload File Size',
            MIMEType: 'Upload MIME Type',
          },

          preparationOfFiles: 'Preparation of files',
          preparationOfFilesForArchiving: 'Preparation of files for archiving',
          filesAreReadyForDownload_one: 'The file are ready for download: ',
          filesAreReadyForDownload_other: 'The files are ready for download: ',

          items: 'Items',
          resetAll: 'Reset all',
          clearAll: 'Clear all',
          noCollectionsAddedYet: 'No collections added yet',

          home: 'Home',
          asset: 'Asset',
          assets: 'Assets',
          logout: 'Logout',
          share: 'Share',

          collections: 'Collections',
          collection: 'Collection',

          account: {
            title: 'Account',
            form: {
              gender: {
                female: 'Female',
                male: 'Male',
                other: 'Other',
                nothing: 'Prefer not to say',
              },

              firstName: 'First Name',
              lastName: 'Last Name',
              email: 'E-mail',
              country: 'Country',
              phone: 'Phone',
              company: 'Which Company do you work for?*',
              companyMessage: 'Please enter which company do you work for',
            },
          },

          personalData: 'Personal Data',
          name: 'Name',
          whatAreYouLookingFor: 'What are you looking for?',
          pleaseEnter: 'Please enter',
          pleaseSelect: 'Please select',
          mediaAssets: 'Media Assets',
          mediaAsset: 'Media Asset',
          add: {
            collection: {
              title: 'Add to collection',
              success: 'Add to collection successfully!',
            },
          },
          create: {
            title: 'Create',
            collection: {
              title: 'Create collection',
              new: 'Create New Collection',
              success: 'Collection created successfully!',
            },
          },
          update: {
            collection: {
              title: 'Edit collection',
              success: 'Collection update successfully!',
            },
          },
          delete: {
            title: 'Delete',
            collection: {
              asset: {
                success: 'Asset removed from collection successfully!',
              },
              modal: {
                sure: 'Are you sure you want to delete this collection?',
                asset: {
                  title: 'Remove item from collection',
                  sure: 'Do you want remove item the collection?',
                  remove: 'Remove',
                },
              },
              title: 'Delete Collection',
              success: 'Collection successfully deleted!',
            },
          },

          adjustments_have_been_saved: {
            success: 'Your adjustments have been successfully saved',
          },
          download: 'Download',
          media: 'Media',
          edit: 'Edit',

          order: {
            'from-new-to-old': 'From newest to oldest',
            'from-old-to-new': 'From oldest to newest',
            'from-0-9': 'From 0 to 9, A-Z',
            'from-9-0': 'From 9 to 0, Z-A',
          },
          result: 'Result',
          lang: 'Lang',

          search: {
            placeholder: 'Search',
          },

          filter: {
            name: 'Filter',
            mediaType: 'All media',
            types: 'Type',
            keywords: 'Keywords',
            restriction: 'Restriction',
            format: 'All formats',
            series: 'Series',
            systems: 'Systems',
            language: 'Language',
            brands: 'Brand',
            publicationType: 'Publication type',
            publicationYear: 'Publication year',
            statements: 'Statement',
            persons: 'Person',
            locations: 'Location',
            surroundings: 'Surrounding',
            references: 'Reference',
            tools: 'Apps / Webtools',
            events: 'Event',
            campaigns: 'Campaign',
          },

          footer: {
            goto: 'Go to',
            imprint: 'Imprint',
            disclaimer: 'Disclaimer',
            dataPrivacy: 'Data Privacy',
            declarations: 'EU declarations',
          },

          usageRights: 'Usage rights',
          properties: 'Properties',
          description: 'Description',

          accept: 'Accept',
          cancel: 'Cancel',
          sendViaEmail: 'Send Via Email',
          shareLinkCreated: 'ShareLink Created',
          yourSharedLink: 'Your Shared Link',
          expiresIn: 'Expires in',
          days: 'days',
          save: 'Save',
          already: 'Already',
          descriptionOptional: 'Description (optional)',

          restrictionsStatus: {
            green: 'No restricted usage',
            orange: 'Restricted usage',
            red: 'Not allowed for use! Please check the usage restrictions.',
            redShort: 'Not allowed for use',
            grey: 'Default',
            default: 'Default',
          },
          usageRestrictionsInformation: 'Usage restrictions information',
          noData: {
            noFound: {
              title: 'Page',
              title2: 'Not found',
              text: "Sorry, we can't seem to find the page you're looking for."
                + ' Let us help you find what you were looking for.',
              link: 'Asset list',
            },
            noResults: {
              title: 'no results',
              found: 'found',
              sorry: "Sorry, we couldn't find any results. Please check your spelling or try different keywords.",
            },
            noCollection: {
              title: 'no collections',
              title2: 'Created',
              text: 'Start your collection right now.',
              asset: {
                title: 'no assets in collections',
              },
            },
          },
          sharedCollection: {
            extend: 'Extend expiration term',
            title: 'Shared collection',
            download: 'Download my selection',
            add: 'Add all',
            clear: 'Clear list',
            expiry: 'Expiry',
            pleaseSelect: 'Please, select file you want to download',
            selection: 'Selection',
            expiresIn: 'Expires in',
            expiresInDays: 'days',
          },
          status: {
            notSet: 'At least one default value is not set.',
            notSet2: 'Please check before use.',
          },
          noteBeforeUse: 'Please note the details before use',

          shareEmail: {
            subject: 'Share Media',
            description: 'Description',
            hello: 'Hello',
            yourSharedLink: 'Your shared link',
            expireDate: 'Expire Date',
            bestRegards: 'Best regards',
          },
        },
      },
      de: {
        translation: {
          owner: 'Eigentümer',

          fileUploadQueue: 'Datei hochladen warteschlange',
          yourDownloadItems: 'Ihre download elemente',
          preview: 'mittlere größe',
          export: 'große größe',
          master: 'original',

          file_one: 'Datei',
          file_other: 'Dateien',
          selected: 'ausgewählt',

          required: {
            name: 'Bitte geben Sie den Namen ein',
            max255: 'Muss kleiner oder gleich 255 zeichen sein',
          },

          id: 'Kennung',
          upload: {
            fileSize: 'Upload-Dateigröße',
            MIMEType: 'MIME-Typ hochladen',
          },

          preparationOfFiles: 'Vorbereitung der dateien',
          preparationOfFilesForArchiving: 'Vorbereitung der dateien für die archivierung',
          filesAreReadyForDownload_one: 'Die datei stehen zum download bereit: ',
          filesAreReadyForDownload_other: 'Die dateien stehen zum download bereit: ',

          items: 'Artikel',
          resetAll: 'Alles zurücksetzen',
          clearAll: 'Alle löschen',
          noCollectionsAddedYet: 'Noch keine Sammlungen hinzugefügt',

          accept: 'Akzeptieren',
          cancel: 'Abbrechen',
          sendViaEmail: 'Per E-Mail senden',
          shareLinkCreated: 'ShareLink erstellt',
          yourSharedLink: 'Ihr freigegebener Link',
          expiresIn: 'Läuft ab in',
          days: 'Tage',
          save: 'Speichern',
          already: 'Bereits',
          descriptionOptional: 'Beschreibung (optional)',
          restrictionsStatus: {
            green: 'Keine eingeschränkte Nutzung',
            orange: 'Eingeschränkte Nutzung',
            red: 'Nicht zur Nutzung zugelassen! Bitte überprüfen Sie die Nutzungsbeschränkungen.',
            redShort: 'Nicht zur Nutzung zugelassen',
            grey: 'Standard',
            default: 'Standard',
          },
          usageRestrictionsInformation: 'Informationen zu Nutzungsbeschränkungen',
          noData: {
            noFound: {
              title: 'Seite',
              title2: 'Nicht gefunden',
              text: 'Entschuldigung, wir können die gesuchte Seite nicht finden.'
                + ' Lassen Sie uns Ihnen helfen, das zu finden, wonach Sie suchen.',
              link: 'Asset liste',
            },
            noResults: {
              title: 'Keine Ergebnisse',
              found: 'gefunden',
              sorry: 'Entschuldigung, wir konnten keine Ergebnisse finden.'
                + ' Bitte überprüfen Sie Ihre Schreibweise oder versuchen Sie andere Schlüsselwörter.',
            },
            noCollection: {
              title: 'Keine Sammlungen',
              title2: 'Erstellt',
              text: 'Starten Sie jetzt Ihre Sammlung.',
              asset: {
                title: 'Keine Assets in den Sammlungen',
              },
            },
          },
          sharedCollection: {
            extend: 'Ablauffrist verlängern',
            title: 'Freigegebene Sammlung',
            download: 'Meine Auswahl herunterladen',
            add: 'Alle hinzufügen',
            clear: 'Liste löschen',
            expiry: 'Ablauf',
            pleaseSelect: 'Bitte wählen Sie die Datei aus, die Sie herunterladen möchten',
            selection: 'Auswahl',
            expiresIn: 'Läuft ab in',
            expiresInDays: 'Tage',
          },
          status: {
            notSet: 'Mindestens ein Standardwert ist nicht gesetzt.',
            notSet2: 'Bitte vor der Verwendung überprüfen.',
          },
          noteBeforeUse: 'Bitte beachten Sie die Details vor der Verwendung',

          home: 'Startseite',
          asset: 'Vermögenswert',
          assets: 'Vermögenswerte',
          logout: 'Abmelden',
          share: 'Teilen',

          collections: 'Sammlungen',
          collection: 'Sammlung',

          account: {
            title: 'Konto',
            form: {
              gender: {
                female: 'Weiblich',
                male: 'Männlich',
                other: 'Andere',
                nothing: 'Möchte nicht angeben',
              },

              firstName: 'Vorname',
              lastName: 'Nachname',
              email: 'E-Mail',
              country: 'Land',
              phone: 'Telefon',
              company: 'Für welches Unternehmen arbeiten Sie?*',
              companyMessage: 'Bitte geben Sie das Unternehmen an, für das Sie arbeiten',
            },
          },

          personalData: 'Personenbezogene Daten',
          name: 'Name',
          whatAreYouLookingFor: 'Wonach suchen Sie?',
          pleaseEnter: 'Bitte eingeben',
          pleaseSelect: 'Bitte auswählen',
          mediaAssets: 'Medien Assets',
          mediaAsset: 'Medien Asset',
          add: {
            collection: {
              title: 'Zur Sammlung hinzufügen',
              success: 'Erfolgreich zur Sammlung hinzugefügt!',
            },
          },
          create: {
            title: 'Erstellen',
            collection: {
              title: 'Sammlung erstellen',
              new: 'Neue Sammlung erstellen',
              success: 'Sammlung erfolgreich erstellt!',
            },
          },
          update: {
            collection: {
              title: 'Sammlung bearbeiten',
              success: 'Sammlung erfolgreich aktualisiert!',
            },
          },
          delete: {
            title: 'Löschen',
            collection: {
              asset: {
                success: 'Asset erfolgreich aus der Sammlung entfernt!',
              },
              modal: {
                sure: 'Sind Sie sicher, dass Sie diese Sammlung löschen möchten?',
                asset: {
                  title: 'Element aus der Sammlung entfernen',
                  sure: 'Möchten Sie das Element aus der Sammlung entfernen?',
                  remove: 'Entfernen',
                },
              },
              title: 'Sammlung löschen',
              success: 'Sammlung erfolgreich gelöscht!',
            },
          },

          adjustments_have_been_saved: {
            success: 'Ihre Anpassungen wurden erfolgreich gespeichert',
          },
          download: 'Herunterladen',
          media: 'Medien',
          edit: 'Bearbeiten',

          order: {
            'from-new-to-old': 'Von neu nach alt',
            'from-old-to-new': 'Von alt nach neu',
            'from-0-9': 'Von 0 bis 9, A-Z',
            'from-9-0': 'Von 9 bis 0, Z-A',
          },
          result: 'Ergebnis',
          lang: 'Sprache',

          search: {
            placeholder: 'Suchen',
          },

          filter: {
            name: 'Filter',
            mediaType: 'Alle Medien',
            types: 'Typ',
            keywords: 'Schlüsselwörter',
            restriction: 'Einschränkung',
            format: 'Alle Formate',
            series: 'Serie',
            systems: 'Systeme',
            language: 'Sprache',
            brands: 'Marke',
            publicationType: 'Veröffentlichungstyp',
            publicationYear: 'Veröffentlichungsjahr',
            statements: 'Aussage',
            persons: 'Person',
            locations: 'Ort',
            surroundings: 'Umgebung',
            references: 'Referenz',
            tools: 'Apps / Webtools',
            events: 'Ereignis',
            campaigns: 'Kampagne',
          },

          footer: {
            goto: 'Gehe zu',
            imprint: 'Impressum',
            disclaimer: 'Haftungsausschluss',
            dataPrivacy: 'Datenschutz',
            declarations: 'EU-Erklärungen',
          },

          usageRights: 'Nutzungsrechte',
          properties: 'Eigenschaften',
          description: 'Beschreibung',

          shareEmail: {
            subject: 'Medien teilen',
            description: 'Beschreibung',
            hello: 'Hallo',
            yourSharedLink: 'Ihr freigegebener Link',
            expireDate: 'Ablaufdatum',
            bestRegards: 'Mit freundlichen Grüßen',
          },
        },
      },
    },
  });

export default i18n;
