import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DurationIcon } from '../../../../Common/Icon';
import { useShareExtend } from '../../../../../hooks/api/share';
import { useMessageSuccess } from '../../../../../hooks/common';
import useComponentDidUpdate from '../../../../../hooks/componentDidUpdate';

export interface ExtendButtonProps {
  onSuccess: () => void;
}

export default function ExtendButton({ onSuccess }: ExtendButtonProps): React.ReactNode {
  const { t } = useTranslation();
  const { shareId } = useParams();
  const shareExtend = useShareExtend(shareId);
  const [loading, setLoading] = useState(false);

  useMessageSuccess([shareExtend], 'The expiration date has been successfully extended!');

  useComponentDidUpdate(() => {
    if (!shareExtend.error && !shareExtend.loading && !loading) {
      onSuccess();
    }
  }, [loading]);

  useEffect(() => {
    if (shareExtend.loading) {
      setLoading(true);
    } else if (loading) {
      setTimeout(() => setLoading(false), 500);
    }
  }, [shareExtend.loading]);

  if (!shareId) {
    return null;
  }

  return (
    <Button
      disabled={loading}
      loading={loading}
      size="large"
      onClick={() => {
        shareExtend.fetch();
      }}
    >
      <DurationIcon />
      {t('sharedCollection.extend')}
    </Button>
  );
}
