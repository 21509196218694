import React, { useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AddCollectionIcon } from '../Icon';
import Point from '../Point';
import AddCollection from '../Modal/Asset/AddCollection';
import { AssetGet } from '../../../hooks/api/asset';
import ImgBackground from '../ImgBackground';

import styles from './index.module.scss';

export interface ContentWrapperProps {
  children?: React.ReactNode
  className?: string | ClassValue;
  to?: string;
  asset: AssetGet;
}

export default function Asset({
  // children,
  to,
  className = '',
  asset,
}: ContentWrapperProps): React.ReactNode {
  const { t } = useTranslation();
  const {
    id, assetId, name, urlPreview, urlExport, mimetypeName, mediaType, restriction,
  } = asset;
  const url = urlPreview || urlExport;
  const [isModalOpenCollection, setIsModalOpenCollection] = useState(false);

  const showModal = () => {
    setIsModalOpenCollection(true);
  };

  const handleOk = () => {
    setIsModalOpenCollection(false);
  };

  const handleCancel = () => {
    setIsModalOpenCollection(false);
  };

  const content = (
    <div className={styles.wrp}>
      <div className={styles.image}>
        <ImgBackground className={styles.bg} url={url || '/resource/images/asset.png'} />
      </div>
      <div className={styles.type}>
        <span className={styles.text}>
          <span>
            {mediaType}
          </span>
          <span>
            {mimetypeName}
          </span>
        </span>
        <span
          role="none"
          className={styles.add}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            showModal();
          }}
        >
          <AddCollectionIcon />
        </span>
      </div>
      <div className={styles.name}>
        {name}
      </div>
      <div className={styles.status}>
        <Point
          status={restriction}
          text={t(`restrictionsStatus.${restriction}`)}
        />
      </div>
    </div>
  );

  if (to) {
    return (
      <>
        <NavLink to={to} className={clsx(styles.asset, className)}>
          {content}
        </NavLink>

        <AddCollection
          id={id}
          assetId={assetId}
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalOpen={isModalOpenCollection}
        />
      </>
    );
  }

  return (
    <div className={clsx(styles.asset, className)}>
      {content}

      <AddCollection
        id={id}
        assetId={assetId}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpenCollection}
      />
    </div>
  );
}
