import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { useTranslation } from 'react-i18next';
import { MenuRoute } from '../../Layout/Menu';
import Imprint from '../../../pages/Imprint';
import { useLang } from '../../../context/lang';

import styles from './index.module.scss';

export interface ContentWrapperProps {
  className?: string | ClassValue;
}

export const routes: MenuRoute[] = [
  {
    privateRoute: true,
    name: 'Imprint',
    route: {
      path: '/imprint',
      element: <Imprint />,
    },
  },
];

export default function Footer({ className = '' }: ContentWrapperProps): React.ReactNode {
  const { lang: { value: lang } } = useLang();
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.footer, className)}>
      <span className={styles.goto}>
        <b>{t('footer.goto')}</b>
        {' '}
        <a
          href="https://www.geberit.com"
          target="_blank"
          title="www.geberit.com"
          rel="noreferrer"
          style={{ marginLeft: 8 }}
        >
          www.geberit.com
        </a>
      </span>

      <div className={clsx(styles.menu, styles.hideMobile)}>
        <span>
          ©
          {' '}
          {2025}
          {' '}
          Geberit International AG
        </span>
        <a href="https://www.geberit.com/impressum" target="_blank" rel="noreferrer">
          <b>{t('footer.imprint')}</b>
        </a>
        <a
          href={lang === 'de'
            ? 'https://www.geberit.com/rechtshinweise'
            : 'https://www.geberit.com/disclaimer'}
          target="_blank"
          rel="noreferrer"
        >
          <b>{t('footer.disclaimer')}</b>
        </a>
        <a
          href={lang === 'de'
            ? 'https://www.geberit.com/datenschutzerklaerung'
            : 'https://www.geberit.com/data-privacy-statement'}
          target="_blank"
          rel="noreferrer"
        >
          <b>{t('footer.dataPrivacy')}</b>
        </a>
        <a
          href="https://www.geberit.com/erklaerungen"
          target="_blank"
          rel="noreferrer"
        >
          <b>{t('footer.declarations')}</b>
        </a>
      </div>

      <div className={clsx(styles.menu, styles.showMobile)}>
        <div>
          <a href="https://www.geberit.com/impressum" target="_blank" rel="noreferrer">
            <b>{t('footer.imprint')}</b>
          </a>
        </div>
        <div>
          <a
            href={lang === 'de'
              ? 'https://www.geberit.com/rechtshinweise'
              : 'https://www.geberit.com/disclaimer'}
            target="_blank"
            rel="noreferrer"
          >
            <b>{t('footer.disclaimer')}</b>
          </a>
        </div>
        <div>
          <a
            href={lang === 'de'
              ? 'https://www.geberit.com/datenschutzerklaerung'
              : 'https://www.geberit.com/data-privacy-statement'}
            target="_blank"
            rel="noreferrer"
          >
            <b>{t('footer.dataPrivacy')}</b>
          </a>
        </div>
        <div>
          <a
            href="https://www.geberit.com/erklaerungen"
            target="_blank"
            rel="noreferrer"
          >
            <b>{t('footer.declarations')}</b>
          </a>
        </div>
        <div>
          <span>
            ©
            {' '}
            {2025}
            {' '}
            Geberit International AG
          </span>
        </div>
      </div>
    </div>
  );
}
