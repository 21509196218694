import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import clsx, { ClassValue } from 'clsx';
import { useTranslation } from 'react-i18next';
import { getFilterText, prettyFormatFilterText } from '../../../../Common/Filter';
import { useLang } from '../../../../../context/lang';
import { MetaDataFeature } from '../../../../../types/asset';
import { LinkWrapperProps } from '../Description';

import styles from './index.module.scss';

export interface PropertiesProps {
  data?: MetaDataFeature[];
  name?: string;
  includeList?: Set<string>;
  excludeList?: Set<string>;
  className?: string | ClassValue;
  wrapper?: (props: LinkWrapperProps) => React.ReactNode;
  iconAfterName?: React.ReactNode;
}

export function getFilteredList(data?: MetaDataFeature[], includeList?: Set<string>, excludeList?: Set<string>) {
  return data
    // eslint-disable-next-line no-nested-ternary
    ?.filter(({ key, ...props }) => (excludeList ? !excludeList.has(key)
      : (includeList ? includeList.has(key)
        : true)) && Object.keys(props).length > 0) || [];
}

export default function List({
  data = undefined, className, name, includeList, excludeList, wrapper, iconAfterName,
}: PropertiesProps): React.ReactNode {
  const { t } = useTranslation();
  const { lang: { value: lang } } = useLang();
  const group = useMemo(() => getFilteredList(data, includeList, excludeList)
    .reduce((acc, value) => {
      const key = value?.key;

      if (key) {
        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(value);
      }

      return acc;
    }, {} as {[key: string]: MetaDataFeature[]}) || {}, [data]);

  return Object.values(group)
    .filter((list) => list.some((item) => item && item?.key))
    .map((list) => (
      <div
        key={list[0].key + list[0].value}
        className={clsx(styles.row, className)}
      >
        <div>
          {getFilterText(list.find((item) => item && item?.name) || list[0], lang, name)}
          {iconAfterName}
        </div>
        <div>
          {list.filter((item) => item && item?.key)
            .map((item) => {
              if (item.values && item.values.length > 0) {
                return item.values.map((item2) => {
                  const text = item?.r?.value || prettyFormatFilterText(t, getFilterText(item2, lang), 'brands') || '-';

                  return (
                    <div key={item2.name}>
                      {wrapper ? wrapper({ item: item2, text, parent: item }) : text}
                    </div>
                  );
                });
              }

              const text = item.value && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}.*/.test(item.value.toString().trim()) ? (
                dayjs(item.value).format('YYYY-MM-DD')
              ) : (
                item.value || '-'
              );

              if (text === '-') {
                return text;
              }

              return (
                <div key={item.key + item.value}>
                  {wrapper ? wrapper({ item, text }) : text}
                </div>
              );
            }).filter(((value, i) => (i === 0 && value === '-') || value !== '-'))}
        </div>
      </div>
    ));
}
