import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import {
  ArrowRightIcon,
  CloseIcon, LogoutIcon, UserIcon,
} from '../../../Common/Icon';
import Lang from '../Lang';

import styles from './index.module.scss';

export default function User(): React.ReactNode {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (isOpen) {
      const fn = (e: any) => {
        if (e.target.closest('.menu_user') === null) {
          setOpen(false);
        }
      };

      document.addEventListener('click', fn, false);

      return () => {
        document.removeEventListener('click', fn);
      };
    }

    return undefined;
  }, [isOpen]);

  return (
    <div className={clsx(styles.user, 'menu_user', { [styles.active]: isOpen })}>
      <a
        role="none"
        className={styles.link}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <UserIcon />
      </a>

      {isOpen ? (
        <>
          <div className={styles.menu}>
            <div className={styles.line} />
            {/* <NavLink to="/account"> */}
            {/*  <UserIcon /> */}
            {/*  {' '} */}
            {/*  {t('account.title')} */}
            {/* </NavLink> */}
            <a
              role="none"
              onClick={(e) => {
                e.preventDefault();
                keycloak.logout({
                  redirectUri: window.location.origin,
                });
              }}
            >
              <LogoutIcon />
              {t('logout')}
            </a>
            <div className={styles.line} />
            <div>
              <Lang className={styles.lang} icon={false}>
                <ArrowRightIcon />
              </Lang>
            </div>
          </div>

          <CloseIcon
            className={styles.close}
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          />
        </>
      ) : null}
    </div>
  );
}
