import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { NavLink } from 'react-router-dom';
import { BigArrowIcon } from '../../../../Common/Icon';
import { AssetByIdGet } from '../../../../../hooks/api/asset';
import List, { getFilteredList } from '../List';

import { MetaDataFeature, MetaDataFeatureChildren } from '../../../../../types/asset';

export interface DescriptionProps {
  data?: AssetByIdGet;
  className?: string | ClassValue;
}

const includeListType = new Set([
  'geberit:tag.type',
]);

const includeListProduct = new Set([
  'geberit:step.product-ref',
]);

const includeListArticle = new Set([
  'geberit:step.product-item-ref',
]);

const includeListPerson = new Set([
  'geberit:with-person',
]);

const includeListCampaign = new Set([
  'geberit:tag.campaign',
]);

const includeList = [
  includeListType,
  includeListProduct,
  includeListArticle,
  includeListPerson,
  includeListCampaign,
];

export interface LinkWrapperProps {
  item: MetaDataFeatureChildren | MetaDataFeature;
  text: string;
  parent?: MetaDataFeature;
  url?: string;
}

function LinkWrapper({
  text, url, // item, parent
}: LinkWrapperProps) {
  return (
    <NavLink to={`${url || ''}`}>
      <BigArrowIcon />
      {text}
    </NavLink>
  );
}

function addUrlToLink(url: string) {
  return function fn(props: LinkWrapperProps) {
    return <LinkWrapper {...props} url={url} />;
  };
}

export function checkDataExist(data?: MetaDataFeature[]) {
  return includeList?.some((include) => getFilteredList(data, include).length);
}

export default function Description({
  data = undefined, className = '',
}: DescriptionProps): React.ReactNode {
  return (
    <div className={clsx(className)}>
      <List
        includeList={includeListType}
        data={data?.allData?.metaData?.feature}
        wrapper={addUrlToLink(`/?types=${data?.types[0]?.id}`)}
      />
      <List
        name="Product"
        includeList={includeListProduct}
        data={data?.allData?.metaData?.feature}
      />
      <List
        name="Article"
        includeList={includeListArticle}
        data={data?.allData?.metaData?.feature}
      />
      <List
        includeList={includeListPerson}
        data={data?.allData?.metaData?.feature}
      />
      <List
        includeList={includeListCampaign}
        data={data?.allData?.metaData?.feature}
        wrapper={addUrlToLink(`/?campaigns=${data?.campaigns[0]?.id}`)}
      />
    </div>
  );
}
