import React, { useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  AddCollectionIcon,
} from '../../../Common/Icon';
import Point from '../../../Common/Point';
import AddCollection from '../../../Common/Modal/Asset/AddCollection';
import Download from '../../../Common/Modal/Asset/Download';
import { AssetByIdGet } from '../../../../hooks/api/asset';
import { ListSize } from '../../Share/SelectedList';
import DownloadButton from '../../../Common/Button/Download';

import styles from './index.module.scss';

export interface InfoProps {
  asset?: AssetByIdGet;
  className?: string | ClassValue;
}

export default function Info({ asset, className = '' }: InfoProps): React.ReactNode {
  const { t } = useTranslation();
  const {
    id, assetId, description, mimetypeName, urlExport, allData, restriction,
  } = asset || {};

  const [isModalOpenCollection, setIsModalOpenCollection] = useState(false);
  const [isModalOpenDownload, setIsModalOpenDownload] = useState<boolean | [ListSize]>(false);

  const showModalCollection = () => {
    setIsModalOpenCollection(true);
  };

  const okCollection = () => {
    setIsModalOpenCollection(false);
  };

  const cancelCollection = () => {
    setIsModalOpenCollection(false);
  };

  const showModalDownload = (key: ListSize = 'export') => {
    setIsModalOpenDownload([key]);
  };

  const okDownload = () => {
    setIsModalOpenDownload(false);
  };

  const cancelDownload = () => {
    setIsModalOpenDownload(false);
  };

  return (
    <div className={clsx(styles.info, className)}>
      <div className={styles.title}>
        <div className={styles.type}>
          {mimetypeName}
        </div>

        {allData?.name}
      </div>
      <div className={styles.rights}>
        {t('usageRights')}
        <div className={styles.usage}>
          <Point
            status={restriction}
            text={t(`restrictionsStatus.${restriction || 'default'}`)}
          />

          {restriction !== 'green' ? (
            <a href="#UsageRights" className={styles.note}>
              {t('noteBeforeUse')}
            </a>
          ) : null}
        </div>
      </div>
      <div className={styles.action}>
        <Button
          size="large"
          icon={<AddCollectionIcon style={{ transform: 'translate(0, 1.5px)' }} />}
          onClick={(e) => {
            e.preventDefault();
            showModalCollection();
          }}
        >
          {t('add.collection.title')}
        </Button>

        {id ? (
          <AddCollection
            id={id}
            assetId={assetId}
            handleCancel={cancelCollection}
            handleOk={okCollection}
            isModalOpen={isModalOpenCollection}
          />
        ) : null}

        <DownloadButton
          asset={asset}
          onClick={showModalDownload}
        />

        <Download
          autoStart={restriction === 'green'}
          urlExport={urlExport}
          isModalOpen={isModalOpenDownload}
          asset={asset}
          handleOk={okDownload}
          handleCancel={cancelDownload}
        />
      </div>
      <div className={styles.line} />
      {description && description.trim().length > 0 ? (
        <div className={styles.description}>
          <div className={styles.name}>
            {t('description')}
          </div>
          <div className={styles.text}>
            {description}
          </div>
        </div>
      ) : null}
    </div>
  );
}
