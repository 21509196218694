import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import ContentShare from '../../components/Pages/Share';
import { useShareGet } from '../../hooks/api/share';

export default function Share(): React.ReactNode | null {
  const { shareId } = useParams();
  const shareGet = useShareGet();

  useEffect(() => {
    if (shareId) {
      shareGet.fetch(undefined, shareId);
    }
  }, [shareId]);

  document.title = shareGet?.data?.name
    ? `${shareGet.data.name} - Geberit Media Portal`
    : 'Share - Geberit Media Portal';

  return (
    <Layout className="center">
      <ContentShare shareGet={shareGet} />
    </Layout>
  );
}
