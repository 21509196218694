import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../Common/Content/Wrapper';
import { LANG_LIST, useLang } from '../../../context/lang';
import { ShareGet } from '../../../hooks/api/share';
import CollectionContent from '../Collections/Collection/Content';
import NotFound from '../NotFound';
import NoAssetsInCollection from '../NoAssetsInCollection';
import Loading from '../../Common/Loading';
import { DownloadIcon, PlusIcon } from '../../Common/Icon';
import Download from '../Collections/Collection/Modal/Download';
import { AssetByIdGet } from '../../../hooks/api/asset';
import SelectedList, { ListSize } from './SelectedList';
import ExtendButton from './Button/Extend';
import { FetchGetId } from '../../../hooks/fetch';

import styles from './index.module.scss';

interface ShareProps {
  shareGet: FetchGetId<ShareGet, string>
}

export default function Share({ shareGet }: ShareProps): React.ReactNode {
  const { t } = useTranslation();
  const [listChecked, setListChecked] = useState<[string, ListSize][]>([]);
  const { setLang } = useLang();
  const { lang, shareId } = useParams();

  useEffect(() => {
    if (LANG_LIST[0] === lang) {
      setLang(LANG_LIST[0]);
    } else {
      setLang(LANG_LIST[1]);
    }
  }, []);

  const assetsChecked = useMemo(() => listChecked.map(([idChecked]) => shareGet.data?.assets
    .find(({ id: uuid }) => uuid === idChecked))
    .filter((value) => value) as AssetByIdGet[], [listChecked, shareGet.data?.assets]);

  const button = (type: string) => function fn(props: any) {
    return (
      <Button
        type={type}
        size="large"
        disabled={listChecked.length === 0}
        {...props}
      >
        <DownloadIcon />
        {t('sharedCollection.download')}
      </Button>
    );
  };

  return (
    <ContentWrapper className={styles.content}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('sharedCollection.title')}
        </div>
      </div>

      <div className={styles.data}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {shareGet.data && shareGet.data.assets.length > 0 && !shareGet.error ? (
          <div className={styles.wrp}>
            <div>
              <CollectionContent
                nameStretch
                listChecked={listChecked}
                setListChecked={setListChecked}
                collection={shareGet.data}
              />
            </div>
            <div className={styles.detail}>
              <div className={styles.title}>
                {t('sharedCollection.selection')}
              </div>

              <div className={styles.actions}>
                <Button
                  disabled={assetsChecked.length === shareGet.data?.assets.length}
                  size="large"
                  type={assetsChecked.length !== shareGet.data?.assets.length ? 'primary' : 'default'}
                  onClick={() => {
                    setListChecked(shareGet.data?.assets?.map(({ id }) => [id, 'export']) || []);
                  }}
                >
                  <PlusIcon />
                  {t('sharedCollection.add')}
                </Button>

                <Download
                  name={shareGet.data?.name || ''}
                  assets={assetsChecked}
                  listChecked={listChecked}
                  button={button(assetsChecked.length === shareGet.data?.assets.length ? 'primary' : 'default')}
                />

                <Button
                  size="large"
                  disabled={listChecked.length === 0}
                  onClick={() => {
                    setListChecked([]);
                  }}
                >
                  {t('sharedCollection.clear')}
                </Button>
              </div>

              <div className={styles.list}>
                {assetsChecked.length > 0 ? (
                  <SelectedList
                    assets={assetsChecked}
                    listChecked={listChecked}
                    setListChecked={setListChecked}
                  />
                ) : (
                  <div className={styles.text}>
                    {t('sharedCollection.pleaseSelect')}
                  </div>
                )}
              </div>

              <div className={styles.title}>
                {t('sharedCollection.expiry')}
              </div>

              {shareGet.data ? (
                <>
                  <div className={styles.expiration}>
                    {t('sharedCollection.expiresIn')}
                    {' '}
                    {dayjs(shareGet.data?.expiration.format('YYYY-MM-DD'))
                      .diff(dayjs(dayjs().format('YYYY-MM-DD')), 'd')}
                    {' '}
                    {t('sharedCollection.expiresInDays')}
                    {' '}
                    (
                    {dayjs(shareGet.data?.expiration).format('DD.MM.YYYY')}
                    )
                  </div>

                  <ExtendButton onSuccess={() => shareGet.fetch(undefined, shareId)} />
                </>
              ) : null}
            </div>
          </div>
        ) : (
          // eslint-disable-next-line no-nested-ternary
          shareGet.loading ? <Loading /> : (
            // eslint-disable-next-line unicorn/no-nested-ternary
            shareGet.error ? <NotFound /> : <NoAssetsInCollection />
          )
        )}
      </div>
    </ContentWrapper>
  );
}
