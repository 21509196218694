import React, { useMemo } from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Item from '../Item';
import Delete from '../Modal/Delete';
import { CollectionByIdGet } from '../../../../../hooks/api/collection';
import { ShareGet } from '../../../../../hooks/api/share';
import { ListSize } from '../../../Share/SelectedList';

import styles from './index.module.scss';

export interface CollectionContentProps {
  collection?: CollectionByIdGet | ShareGet;
  actions?: { [key: string]: () => void };
  listChecked: [string, ListSize][];
  setListChecked: (list: [string, ListSize][]) => void;
  nameStretch?: boolean;
}

export default function CollectionContent({
  collection, actions, listChecked, setListChecked, nameStretch = false,
}: CollectionContentProps): React.ReactNode {
  const { t } = useTranslation();
  const listSize: Array<ListSize> = ['preview', 'export', 'master'];
  const visibleAssets = useMemo(
    () => collection?.assets.filter(({ visibility }) => visibility) || [],
    [collection?.assets],
  );

  return (
    <>
      {collection && collection?.assets.length ? (
        <div className={styles.checkboxCounter}>
          <Checkbox
            checked={visibleAssets.length === listChecked.length}
            onClick={() => {
              if (visibleAssets.length === listChecked.length) {
                setListChecked([]);
              } else {
                setListChecked(visibleAssets.map(({ id: uuid }) => [uuid, listSize[1]]) || []);
              }
            }}
          />
          {listChecked.length}
          {' '}
          {t('file', { count: listChecked.length })}
          {' '}
          {t('selected')}
        </div>
      ) : null}

      <div className={styles.list}>
        {collection?.assets.map(({
          id: uuid, visibility, assetId, name, restriction, description, urlPreview, urlExport,
        }) => (
          <div
            key={uuid}
            className={clsx(styles.wrp, { [styles.hidden]: !visibility })}
          >
            <div className={styles.selector}>
              <Checkbox
                checked={listChecked.some(([id]) => id === uuid)}
                onClick={() => {
                  if (visibility) {
                    if (listChecked.some(([id]) => id === uuid)) {
                      setListChecked(listChecked.filter(([id]) => id !== uuid));
                    } else {
                      setListChecked([[uuid, listSize[1]], ...listChecked]);
                    }
                  }
                }}
              />
            </div>

            <Item
              restriction={restriction}
              nameStretch={nameStretch}
              id={uuid}
              to={`/asset/${assetId}`}
              url={urlPreview || urlExport}
              name={name}
              description={description}
              gridSize="minmax(88px, 250px) minmax(58%, 1fr)"
              className={styles.item}
              actions={(
                  actions?.delete ? (
                    <Delete
                      id={uuid}
                      collection={collection}
                      onSuccess={actions.delete}
                    />
                  ) : undefined
                )}
            />
          </div>
        ))}
      </div>
    </>
  );
}
