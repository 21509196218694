import React, { useState } from 'react';

import DownloadModal from '../../../../../Common/Modal/Collections/Download';
import { AssetByIdGet } from '../../../../../../hooks/api/asset';
import { ListSize } from '../../../../Share/SelectedList';
import { DownloadButtonProps } from '../../../../../Common/Button/Download';

export interface DownloadProps {
  name: string;
  assets: AssetByIdGet[];
  listChecked?: [string, ListSize][];
  button?: (props: DownloadButtonProps) => React.ReactNode
}

export default function Download({
  name,
  assets,
  listChecked,
  button: Button,
}: DownloadProps): React.ReactNode {
  const [isModalOpen, setIsModalOpen] = useState<ListSize | boolean>(false);

  const showModal = (type: ListSize) => {
    setIsModalOpen(type);
  };

  const ok = () => {
    setIsModalOpen(false);
  };

  const cancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {Button ? (
        <Button
          role="none"
          onClick={(type: ListSize) => {
            showModal(type);
          }}
        />
      ) : null}
      <DownloadModal
        type={isModalOpen}
        name={name}
        assets={assets}
        listChecked={listChecked}
        isModalOpen={isModalOpen}
        handleOk={ok}
        handleCancel={cancel}
      />
    </>
  );
}
