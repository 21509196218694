import React from 'react';

export interface ImgBackgroundProps {
  className: string;
  url?: string
}

export default function ImgBackground({ className, url }: ImgBackgroundProps): React.ReactNode {
  return (
    <>
      <div
        className={className}
        style={{ backgroundImage: `url('${url || '/resource/images/asset.png'}')` }}
      />
      <img
        alt={url}
        title={url}
        src={url || '/resource/images/asset.png'}
        style={{
          opacity: 0, position: 'absolute', left: -99999, top: -99999, pointerEvents: 'none',
        }}
        onLoad={(e: any) => {
          const { width, height } = e.target.getBoundingClientRect();

          if (e.target && e.target.previousSibling) {
            const ration = Math.max(0.75, Math.min(1 / Math.max(Math.min(height / width, 3), 0.3), 3));

            e.target.previousSibling.style.aspectRatio = ration;
          }
          e.target.remove();
        }}
        onError={(e: any) => {
          if (e.target && e.target.previousElementSibling) {
            e.target.previousElementSibling.style.backgroundImage = ''
              + 'url(\'/resource/images/asset.png\')';
          }
        }}
      />
    </>
  );
}
